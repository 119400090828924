import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FileText, Settings, DollarSign } from "lucide-react";
import "../Staticfiles/EnterpriseSolutions.css";

gsap.registerPlugin(ScrollTrigger);

const EnterpriseSolutions = () => {
    const sectionRef = useRef(null);
    const cardsRef = useRef([]);

    useEffect(() => {
        cardsRef.current.forEach((card, index) => {
            let xValue = index === 0 ? -100 : index === 2 ? 100 : 0;
            let yValue = index === 1 ? 100 : 0;

            gsap.fromTo(
                card,
                { opacity: 0, x: xValue, y: yValue, scale: 0.8 },
                {
                    opacity: 1,
                    x: 0,
                    y: 0,
                    scale: 1,
                    duration: 4,
                    ease: "power4.out",
                    scrollTrigger: {
                        trigger: sectionRef.current,
                        start: "top 75%",
                        end: "top 40%",
                        scrub: 1.5,
                    },
                }
            );
        });
    }, []);

    const solutions = [
        {
            shortTitle: "Unmatched Excellence",
            description: [
                "Enterprise-grade performance you can trust, driven by scale, speed, and a commitment to conversion optimization.",
            ],
            icon: <Settings size={24} color="#005551" />,
            className: "llm",
        },
        {
            shortTitle: "Absolute control",
            description: [
                "Our platform is designed to provide you with ultimate control over protection because no one understands your business better than you.",
            ],
            icon: <FileText size={24} color="#005551" />,
            className: "idp",
        },
        {
            shortTitle: "Decision Transparency",
            description: [
                "Empowering informed decisions and optimizing business performance through actionable data insights.",
            ],
            icon: <FileText size={24} color="#005551" />,
            className: "faas",
        },
        {
            shortTitle: "Rapid Value Realization",
            description: [
                "Fraud waits for no one and neither does Fintech. Our models are engineered for the quickest ROI.",
            ],
            icon: <Settings size={24} color="#005551" />,
            className: "llm",
        },
        {
            shortTitle: "Seamless Integration",
            description: [
                "Effortlessly integrate with your existing systems using our flexible APIs, ensuring minimal disruption and maximum efficiency.",
            ],
            icon: <FileText size={24} color="#005551" />,
            className: "idp",
        },
        {
            shortTitle: "Adaptive Intelligence",
            description: [
                "Stay ahead of evolving threats with AI-powered solutions that continuously learn and adapt to protect your business.",
            ],
            icon: <FileText size={24} color="#005551" />,
            className: "faas",
        },
    ];

    return (
        <section className="enterprise-solutions" ref={sectionRef}>
            <div className="container">
                <h2 className="section-titlle">
                    Why AuthRabbit
                </h2>

                <div className="row">
                    {solutions.map((solution, index) => (
                        <div className="col-md-4 mt-3" key={index}>
                            <div className="solution-card" ref={(el) => (cardsRef.current[index] = el)}>
                                <div className="card-head">
                                    <div className="icon-container">{solution.icon}</div>
                                    <div className="card-title">
                                        <h1 className={`solution-title ${solution.className}`}>{solution.shortTitle}</h1>
                                        <p className="card-description">{solution.description[0]}</p>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <a href="#" className="btn btn-outline-light btn-learn-more">LEARN MORE →</a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default EnterpriseSolutions;
