import React from "react";
import "../Staticfiles/BlogCards.css";

const BlogCards = () => {
    const blogs = [
        {
            title: "AI-powered fintech the next big bet",
            description:
                "Beyond fintech, B Capital is also placing bets on robotics, space technology and advanced manufacturing in sectors such as electric vehicles, defence, and aerospace.",
            image: "https://www.financialexpress.com/wp-content/uploads/2025/02/ai.freepik.jpg?w=1024",
            link: "https://www.financialexpress.com/business/sme-ai-powered-fintech-the-next-big-bet-3753295/",
        },
        {
            title: "The future of credit lies in technology: How credit line on UPI will supercharge inclusion in India",
            description:
                "Credit Line on UPI is a more structured, transparent, and sustainable credit ecosystem that leverages UPI to enable",
            image: "https://www.financialexpress.com/wp-content/uploads/2024/12/UPI.PTI_.jpg?w=1024",
            link: "https://www.financialexpress.com/business/banking-finance-the-future-of-credit-lies-in-technology-how-credit-line-on-upi-will-supercharge-inclusion-in-india-3747475/",
        },
        {
            title: "Juspay set to become first unicorn of 2025",
            description:
                "In FY24, Juspay reported revenues of Rs 319.32 crore, up 49.6% from Rs 213.39 crore in FY23, while reducing losses by 10% to Rs 97.5 crore.",
            image: "https://www.financialexpress.com/wp-content/uploads/2025/01/juspay.Linkedin.jpg?w=1024",
            link: "https://www.financialexpress.com/business/industry-juspay-set-to-become-first-unicorn-of-2025-3728049/",
        },
        {
            title: "Re-shaping the consumer behaviour",
            description:
                "High fintech adoption isn’t just a trend but a game changer in India; three ‘S’s will define 2025 for the sector — security, sustainability, and scalability.",
            image: "https://www.financialexpress.com/wp-content/uploads/2025/01/rupee.freepk_cce99a.jpg?w=1024",
            link: "https://www.financialexpress.com/opinion/re-shaping-the-consumer-behaviour/3710631/",
        },
        {
            title: "New business initiatives will be a key driver of growth",
            description:
                "PoSPs are usually small agents who are typically associated with only one insurance company. ",
            image: "https://www.financialexpress.com/wp-content/uploads/2024/10/policybazar.FB_.jpg?w=1024",
            link: "https://www.financialexpress.com/business/banking-finance-new-business-initiatives-will-be-a-key-driver-of-growth-3648787/",
        },
        {
            title: "Olyv’s Rohit Garg on company’s growth strategies and marketing plans after rebranding ",
            description:
                "This year, we plan to up our acquisition efforts by bringing more partners on board to create a stronger engine",
            image: "https://unsplash.it/600/300?image=60",
            link: "https://www.financialexpress.com/business/brandwagon-olyvs-rohit-garg-on-companys-growth-strategies-and-marketing-plans-after-rebrandingnbsp-3588086/",
        },
        {
            title: "Viksit Bharat vision hinges on economic growth with inclusive development, digital penetration, social welfar",
            description:
                "he recent trends on India’s fiscal health remain favourable offering a promising outlook for the country's economic stability and growth",
            image: "https://www.financialexpress.com/wp-content/uploads/2024/07/growth-freepik1.jpg?w=1024",
            link: "https://www.financialexpress.com/business/sme/viksit-bharat-vision-hinges-on-economic-growth-with-inclusive-development-digital-penetration-social-welfare/3559750/",
        },
        {
            title: "Union Budget 2024: Fintech industry seeks tax incentives to boost consumption, drive growth",
            description:
                "Ahead of the budget, the fintech industry has several expectations from the government, which aim to support the sector, helping India achieve its goal of becoming the third largest economy in the world.",
            image: "https://www.financialexpress.com/wp-content/uploads/2024/07/lending-app-Freepik.jpg?w=1024",
            link: "https://www.financialexpress.com/budget/union-budget-2024-fintech-industry-seeks-tax-incentives-to-boost-consumption-drive-growth-3556379/",
        },
    ];

    return (
        <section className="uniqueBlog-section" id="NewsSection">
            <h2 className="section-title text-center">News/Blogs</h2>
            <div className="row mt-4">
                {blogs.map((blog, index) => (
                    <div className="col-md-3" key={index}> {/* col-md-3 for smaller columns */}
                        <div className="uniqueBlog-card">
                            <img
                                src={blog.image}
                                alt={blog.title}
                                className="uniqueBlog-card-img"
                            />
                            <div className="uniqueBlog-card-content">
                                <h3 className="uniqueBlog-card-title">{blog.title}</h3>
                                <p className="uniqueBlog-card-text">{blog.description}</p>
                                <a href={blog.link} target="_blank" className="uniqueBlog-card-link">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default BlogCards;
