import React, { useEffect, useRef } from "react";
import "../Staticfiles/ProgressDesign.css";
import { Button } from "react-bootstrap";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ProgressDesign = () => {
    const timelineRef = useRef(null);
    const progressLineRef = useRef(null);


    const handleScroll = () => {
        const section = document.getElementById('targetSection');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };


    useEffect(() => {
        const steps = gsap.utils.toArray(".timeline-item");

        gsap.fromTo(
            progressLineRef.current,
            { height: "0%" },
            {
                height: "100%",
                ease: "power1.out",
                scrollTrigger: {
                    trigger: timelineRef.current,
                    start: "top 20%",
                    end: "bottom 50%",
                    scrub: true,
                },
            }
        );

        steps.forEach((step, index) => {
            gsap.fromTo(
                step,
                { opacity: 0.3 },
                {
                    opacity: 1,
                    duration: 0.2,
                    scrollTrigger: {
                        trigger: step,
                        start: "top 80%",
                        end: "top 30%",
                        scrub: true,
                    },
                }
            );
        });
    }, []);

    return (
        <section className="progress-section mt-5">
            <div className="container">
                <h2 className="text-center fw-bold">How it Works?</h2>
                <div className="row mt-4">


                    <div className="col-md-5 text-center">
                        <h5 className="journey-title">AuthRabbit Journey</h5>
                        <h2 className="journey-heading">
                            Verify Your KYC Details <br />
                            with Low Latency
                        </h2>
                        <Button className="request-btn" onClick={handleScroll}>Request Callback →</Button>
                    </div>

                    {/* Right Side Timeline */}
                    <div className="col-md-7 mt-5">
                        <div className="timeline" ref={timelineRef}>
                            <div className="progress-line" ref={progressLineRef}></div>
                            {[
                                {
                                    "step": "Step 1",
                                    "title": "Get Started",
                                    "subtitle": "Request API Access",
                                    "details": [
                                        "Send an email request",
                                        "Fill out the contact form",
                                        "Connect with an API consultant"
                                    ]
                                },
                                {
                                    "step": "Step 2",
                                    "title": "Authorization",
                                    "subtitle": "Receive Your API Credentials",
                                    "details": [
                                        "Obtain your Client ID & Auth Token",
                                        "Log in to the Developer Dashboard",
                                        "Engage with our industry experts"
                                    ]
                                },
                                {
                                    "step": "Step 3",
                                    "title": "Exploration",
                                    "subtitle": "Test & Evaluate Our APIs",
                                    "details": [
                                        "Gain access to API endpoints",
                                        "Use sample data for testing",
                                        "Preview real-time responses"
                                    ]
                                },
                                {
                                    "step": "Step 4",
                                    "title": "Seamless Integration",
                                    "subtitle": "Deploy APIs in Production",
                                    "details": [
                                        "Obtain the production API URL",
                                        "Use structured request bodies",
                                        "Retrieve and process API responses"
                                    ]
                                },
                                {
                                    "step": "Step 5",
                                    "title": "Achieve Success",
                                    "subtitle": "Leverage API Data Effectively",
                                    "details": [
                                        "Map response data to your application",
                                        "Ensure seamless verification & integration"
                                    ]
                                }
                            ].map((item, index) => (
                                <div className="timeline-item" key={index}>
                                    <div className="step-badge">{item.step}</div>
                                    <div className="timeline-content">
                                        <h4 className="step-title">{item.title}</h4>
                                        <h5 className="step-subtitle">{item.subtitle}</h5>
                                        <ul>
                                            {item.details.map((point, i) => (
                                                <li key={i}>{point}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProgressDesign;
