import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css"; // Bootstrap Icons Include
import "../Staticfiles/EnterpriseProducts.css";
import VoterImage from '../Images/votercardimage.png'
import AadhaarImage from '../Images/aadhaarcardimage.png'
import PanImage from '../Images/pancardimage.png'
import DlImage from '../Images/dlimage.png'
import DocsOcr from '../Images/ocrimage.png'
import VideoKYC from '../Images/videokyc.jpg'
import CrAuthrabbit from '../Images/criminalcheckimagewebsite.png'
import UAEImage from '../Images/uaeimage.png'

// gsap.registerPlugin(ScrollTrigger);

const EnterpriseProducts = () => {
    const cardsRef = useRef([]);

    // useEffect(() => {
    //     cardsRef.current.forEach((card, index) => {
    //         gsap.fromTo(
    //             card,
    //             { opacity: 0, rotateY: -90, scale: 0.8, y: 50 },
    //             {
    //                 opacity: 1,
    //                 rotateY: 0,
    //                 scale: 1,
    //                 y: 0,
    //                 duration: 0.9,
    //                 ease: "power4.out",
    //                 delay: index * 0.2,
    //                 scrollTrigger: {
    //                     trigger: card,
    //                     start: "top 65%",
    //                     end: "bottom 20%",
    //                     toggleActions: "play none none reverse",
    //                 },
    //             }
    //         );
    //     });
    // }, []);

    const products = [
        { title: "Aadhaar Verification API", description: "Use the EPIC number of the Voter ID provided by the customer to access critical data for seamless onboarding and service delivery.", icon: "bi-credit-card", image: VoterImage },
        { title: "Voter ID Verification API", description: "Use the EPIC number of the Voter ID provided by the customer to access critical data for seamless onboarding and service delivery.", icon: "bi-person-badge", image: AadhaarImage },
        { title: "Pan Auth API", description: "Authenticate your customer's identity with real-time PAN verification API, ensuring accuracy in Name, Date of Birth, and PAN number validation.", icon: "bi-credit-card", image: PanImage },
        { title: "DL Verification API", description: "Verify customers' driving licenses to ensure legitimacy and prevent fraudulent activities.", icon: "bi-credit-card", image: DlImage },
        { title: "UAE ID Verification", description: "UAE ID Verification ensures fast and secure identity and document verification, enabling smooth onboarding with full compliance.", icon: "bi-credit-card", image: UAEImage },
        { title: "Video KYC", description: "Video KYC makes customer verification easy and secure by allowing real-time identity checks through a simple video call.", icon: "bi-camera-video", image: VideoKYC },
        { title: "Criminal Check", description: "Criminal Check provides fast and reliable background screening to ensure secure and trusted onboarding.", icon: "bi-credit-card", image: CrAuthrabbit },
        { title: "Document OCR", description: "Document OCR extracts text and data from documents quickly and accurately, streamlining verification and processing tasks.", icon: "bi-file-earmark-break", image: DocsOcr },
    ];

    return (
        <section className="uniquePro-enterprise-products container" id="ProductSection">
            <div className="row">
                <h2 className="text-center fw-bold mt-5 uniquePro-heading">Our Products</h2>
                {products.map((product, index) => (
                    <div
                        className="col-md-3 d-flex align-items-stretch"
                        key={index}
                        ref={(el) => (cardsRef.current[index] = el)}
                    >
                        <div className={`uniquePro-card mt-5 text-white`}>
                            <div className="uniquePro-card-head">
                                <img src={product.image} alt={product.title} className="uniquePro-card-img" />
                            </div>
                            <div className="uniquePro-card-body text-center">
                                <i className={`bi ${product.icon} uniquePro-card-icon`}></i> {/* Bootstrap Icon */}
                                <h4 className="uniquePro-card-title">{product.title}</h4>
                                <p className="uniquePro-card-text">{product.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default EnterpriseProducts;
