import React from "react";
import "../Staticfiles/BusinessSafety.css"; // Import the custom CSS file

const BusinessSafety = () => {
    return (
        <div className="business-safety-container">
            <h2 className="heading">Your Business Is Safe With Us</h2>
            <div className="certificates">
                <div className="certificate-card">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/f/fc/ISO_english_logo.svg"  // ISO logo URL
                        alt="ISO/IEC 27001"
                        className="certificate-icon"
                    />
                    <div className="certificate-text">
                        <h3>ISO/IEC 27001:2013 Certified</h3>
                        <p>Information Security Management</p>
                    </div>
                </div>
                <div className="certificate-card">
                    <img
                        src="https://us.123rf.com/450wm/popcornarts/popcornarts2312/popcornarts231201530/220633625-encryption-and-data-icon-concept.jpg?ver=6"  // 256-bit Encryption logo URL
                        alt="256-BIT Encryption"
                        className="certificate-icon"
                    />
                    <div className="certificate-text">
                        <h3>256-BIT Encryption</h3>
                        <p>256-bit Encryption</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessSafety;
