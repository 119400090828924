import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import "../Staticfiles/Footer.css";
import logo from "../Images/blacklogoforfooter.png";
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
    const footerRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(
            footerRef.current,
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                duration: 1.5,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: footerRef.current,
                    start: "top 90%",
                    end: "top 80%",
                    scrub: 1,
                },
            }
        );
    }, []);

    return (
        <footer className="footer" ref={footerRef}>
            <div className="footer-container">
                {/* Left Section - Logo & Description */}
                <div className="footer-logo-section">
                    <img src={logo} alt="6ESTATES Logo" className="footer-logo" />
                    <p>Your trusted platform for document authentication and verification.</p>
                </div>

                {/* Center Section - Quick Links */}
                <div className="footer-links">
                    <h3>Quick Links</h3>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Contact</a></li>
                        <li><a href="#">Industries</a></li>
                        <li><a href="#">News</a></li>
                        <li><a href="#">About</a></li>
                    </ul>
                </div>

                {/* Right Section - Contact Info */}
                <div className="footer-contact">
                    <h3>Contact Us</h3>
                    <p>Email: info@authrabbit.com</p>
                    <p>Phone: +91 9187XXXXXXX</p>
                    <p>SEZ, Gurugram, Haryana</p>
                </div>
            </div>

            <div className="footer-socials">
                <h3>Follow Us</h3>
                <div className="social-icons">
                    <a href="#" className="social-icon"><FaFacebook /></a>
                    <a href="#" className="social-icon"><FaInstagram /></a>
                    <a href="#" className="social-icon"><FaLinkedin /></a>
                    <a href="#" className="social-icon"><FaWhatsapp /></a>
                </div>
            </div>

            <p className="footer-text">© 2025 AuthRabbit. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
