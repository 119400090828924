import React, { useState } from "react";
import "../Staticfiles/FAQ.css"; // Ensure your CSS is linked properly
import faqImage from "../Images/Questions-amico.png"; // Apni image ka path yahan dalna

const FAQ = () => {
    const faqs = [
        { question: "What is your service about?", answer: "Our service provides secure document authentication and verification." },
        { question: "How can I get an auth key?", answer: "First, You need to Fill the contact form and sending query to use by submitting it and Our Agent will contact you by email and phone then You got Client ID and Key for accessing your dashboard." },
        { question: "Is my data secure?", answer: "Yes, we use industry-standard encryption to keep your data safe." },
        { question: "Can I integrate this with my website?", answer: "Absolutely! We offer APIs that can be easily integrated." },
        { question: "Do you provide customer support?", answer: "Yes, our support team is available 24/7 to assist you." }
    ];

    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section className="faq-section">
            <div className="faq-container">
                {/* Left Column: Image */}
                <div className="faq-image">
                    <img src={faqImage} alt="FAQ" />
                </div>

                {/* Right Column: FAQs */}
                <div className="faq-content">
                    <h2 className="faq-title">Frequently Asked Questions</h2>
                    {faqs.map((faq, index) => (
                        <div key={index} className={`faq-item ${openIndex === index ? "open" : ""}`} onClick={() => toggleFAQ(index)}>
                            <div className="faq-question">
                                <span className="faq-question-text">{faq.question}</span>
                                <span className="faq-icon">{openIndex === index ? "−" : "+"}</span>
                            </div>
                            {openIndex === index && <div className="faq-answer">{faq.answer}</div>}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQ;
