import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../Staticfiles/HeroSection.css";

gsap.registerPlugin(ScrollTrigger);

const HeroSection = () => {
    const sectionRef = useRef(null);
    const canvasRef = useRef(null);


    const handleScroll = () => {
        const section = document.getElementById('targetSection');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const particles = [];
        const maxDistance = 100; // ⬅ Kam kiya connection distance
        const repelRadius = 80;  // ⬅ Cursor repel effect optimized
        let mouse = { x: null, y: null };

        // 🎯 Generate Fewer Particles (30 instead of 50)
        for (let i = 0; i < 30; i++) {
            particles.push({
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                radius: Math.random() * 3 + 1, // ⬅ Size slightly optimized
                dx: (Math.random() - 0.5) * 1.2, // ⬅ Speed adjusted
                dy: (Math.random() - 0.5) * 1.2,
            });
        }

        // 🖱️ Cursor Interaction
        window.addEventListener("mousemove", (e) => {
            mouse.x = e.clientX;
            mouse.y = e.clientY;
        });

        function drawParticles() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            particles.forEach((p) => {
                // Move Particles
                p.x += p.dx;
                p.y += p.dy;

                // Bounce Back from Edges
                if (p.x < 0 || p.x > canvas.width) p.dx *= -1;
                if (p.y < 0 || p.y > canvas.height) p.dy *= -1;

                // Cursor Repel Effect (Adjusted for Smoothness)
                let mouseDist = Math.hypot(mouse.x - p.x, mouse.y - p.y);
                if (mouseDist < repelRadius) {
                    let angle = Math.atan2(p.y - mouse.y, p.x - mouse.x);
                    p.dx += Math.cos(angle) * 0.3; // ⬅ Reduced force for smooth movement
                    p.dy += Math.sin(angle) * 0.3;
                }

                // Draw Particles
                ctx.beginPath();
                ctx.arc(p.x, p.y, p.radius, 0, Math.PI * 2);
                ctx.fillStyle = "#0a9d96";
                ctx.fill();

                // Connect Particles with Lines (Reduced connections)
                particles.forEach((other) => {
                    let dist = Math.hypot(p.x - other.x, p.y - other.y);
                    if (dist < maxDistance) {
                        ctx.beginPath();
                        ctx.moveTo(p.x, p.y);
                        ctx.lineTo(other.x, other.y);
                        ctx.strokeStyle = `rgba(10, 157, 150, ${1 - dist / maxDistance})`;
                        ctx.lineWidth = 1;
                        ctx.stroke();
                    }
                });
            });

            requestAnimationFrame(drawParticles);
        }

        drawParticles();

        // 🎬 GSAP Animation on Scroll (No Change Here)
        gsap.fromTo(
            sectionRef.current,
            { opacity: 0, y: 100 },
            {
                opacity: 1,
                y: 0,
                duration: 1.5,
                ease: "power4.out",
                scrollTrigger: {
                    trigger: sectionRef.current,
                    start: "top 80%",
                    end: "bottom top",
                    scrub: 1.5,
                },
            }
        );
    }, []);


    return (
        <section className="hero-section" ref={sectionRef}>

            <canvas ref={canvasRef} className="hero-background"></canvas>

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h3>Secure Your World with Trusted Verification</h3>
                        <h2 id="underborders" className="mt-3">
                            Seamless Onboarding. Secure Authentication
                        </h2>
                        <p>Innovative solutions tailored to elevate your business.</p>
                        <a href="#" className="btn btn-primary" onClick={handleScroll}>GET API KEY →</a>
                    </div>
                    <div className="col-md-6 text-center img-fluid">

                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
