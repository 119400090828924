import React, { useState, useEffect, useRef } from "react";
import { Container, Nav, Navbar as BootstrapNavbar } from "react-bootstrap";
import { BiHome, BiLayer, BiMoney, BiBrain, BiInfoCircle, BiChevronDown, BiMenu, BiBuilding, BiNews, BiEnvelope } from "react-icons/bi";
import "../Staticfiles/Navbar.css";

const Navbar = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showIndustryDropdown, setShowIndustryDropdown] = useState(false); // 🆕 Industries dropdown state
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const sidebarRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            if (window.innerWidth > 768) {
                setShowSidebar(false);
                setShowDropdown(false);
                setShowIndustryDropdown(false);
            }
        };

        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setShowSidebar(false);
            }
        };

        window.addEventListener("resize", handleResize);
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleDropdownToggle = () => {
        if (isMobile) setShowDropdown(!showDropdown);
    };

    const handleIndustryDropdownToggle = () => {
        if (isMobile) setShowIndustryDropdown(!showIndustryDropdown);
    };

    const handleNavClick = () => {
        if (isMobile) setShowSidebar(false);
        const section = document.getElementById('targetSection');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleNews = () => {
        if (isMobile) setShowSidebar(false);
        const section = document.getElementById('NewsSection');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScroll = () => {
        const section = document.getElementById('targetSection');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleScrollToNews = () => {
        const section = document.getElementById('NewsSection');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleScrollToProducts = () => {
        const section = document.getElementById('ProductSection');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <>
            {isMobile && (
                <button className="sidebar-toggle" onClick={() => setShowSidebar(!showSidebar)}>
                    <BiMenu size={30} />
                </button>
            )}

            {/* 📱 Mobile Sidebar */}
            {isMobile && (
                <div ref={sidebarRef} className={`mobile-sidebar ${showSidebar ? "open" : ""}`}>
                    <button className="close-btn" onClick={() => setShowSidebar(false)}>✖</button>

                    <div className="sidebar-logo"></div>

                    <Nav className="sidebar-nav">

                        <div className={`custom-dropdown ${showDropdown ? "show" : ""}`} onClick={handleDropdownToggle}>
                            <button className="dropdown-toggle">
                                Products
                            </button>
                            <div className={`dropdown-menu ${showDropdown ? "show" : ""}`}>
                                <a className="dropdown-item" href="#" onClick={handleNavClick}><BiBrain /> EPIC VERIFICATION</a>
                                <a className="dropdown-item" href="#" onClick={handleNavClick}><BiLayer /> PAN STATUS</a>
                                <a className="dropdown-item" href="#" onClick={handleNavClick}><BiMoney /> DRIVING LICENSE</a>
                                <a className="dropdown-item" href="#" onClick={handleNavClick}><BiMoney /> PASSPORT VERIFICATION</a>
                                <a className="dropdown-item" href="#" onClick={handleNavClick}><BiMoney /> DOCUMENTS OCR</a>
                                <a className="dropdown-item" href="#" onClick={handleNavClick}><BiMoney /> PAN AADHAAR LINK</a>
                            </div>
                        </div>


                        <div className={`custom-dropdown ${showIndustryDropdown ? "show" : ""}`} onClick={handleIndustryDropdownToggle}>
                            <button className="dropdown-toggle">
                                <BiBuilding /> Industries
                            </button>
                            <div className={`dropdown-menu ${showIndustryDropdown ? "show" : ""}`}>
                                <a className="dropdown-item" href="#" onClick={handleNavClick}>Fintech</a>
                                <a className="dropdown-item" href="#" onClick={handleNavClick}>Crypto</a>
                                <a className="dropdown-item" href="#" onClick={handleNavClick}>Retail</a>
                            </div>
                        </div>

                        <Nav.Link href="#" onClick={handleNews}><BiNews /> News</Nav.Link>
                        <Nav.Link href="#" onClick={handleNavClick}><BiEnvelope /> Contact</Nav.Link>
                        <Nav.Link href="#" onClick={handleNavClick}><BiInfoCircle /> About</Nav.Link>
                    </Nav>
                </div>
            )}

            {/* 💻 Desktop Navbar */}
            {!isMobile && (
                <BootstrapNavbar expand="lg" className="navbar-custom py-3">
                    <Container>
                        <BootstrapNavbar.Brand href="#">
                            <div className="logo"></div>
                        </BootstrapNavbar.Brand>
                        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
                        <BootstrapNavbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">

                                {/* 🧩 Products Dropdown */}
                                <div
                                    className="custom-dropdown"
                                    onMouseEnter={() => setShowDropdown(true)}
                                    onMouseLeave={() => setShowDropdown(false)}
                                >
                                    <button className="dropdown-toggle">
                                        <BiLayer /> Products
                                    </button>
                                    <div className={`dropdown-menu ${showDropdown ? "show" : ""}`}>
                                        <a className="dropdown-item" onClick={handleScrollToProducts} href="#"><BiLayer /> EPIC VERIFICATION</a>
                                        <a className="dropdown-item" onClick={handleScrollToProducts} href="#"><BiLayer /> PAN STATUS</a>
                                        <a className="dropdown-item" onClick={handleScrollToProducts} href="#"><BiLayer /> DRIVING LICENSE</a>
                                        <a className="dropdown-item" onClick={handleScrollToProducts} href="#"><BiLayer /> PASSPORT VERIFICATION</a>
                                        <a className="dropdown-item" onClick={handleScrollToProducts} href="#"><BiLayer /> PAN AADHAAR LINK</a>
                                        <a className="dropdown-item" onClick={handleScrollToProducts} href="#"><BiLayer /> DOCUMENT OCR</a>
                                    </div>
                                </div>

                                {/* 🏭 Industries Dropdown with Dummy Options */}
                                <div
                                    className="custom-dropdown"
                                    onMouseEnter={() => setShowIndustryDropdown(true)}
                                    onMouseLeave={() => setShowIndustryDropdown(false)}
                                >
                                    <button className="dropdown-toggle">
                                        <BiBuilding /> Industries
                                    </button>
                                    <div className={`dropdown-menu ${showIndustryDropdown ? "show" : ""}`}>
                                        <a className="dropdown-item" href="#">Fintech</a>
                                        <a className="dropdown-item" href="#">Crypto</a>
                                        <a className="dropdown-item" href="#">Retail</a>
                                    </div>
                                </div>

                                <Nav.Link href="" onClick={handleScrollToNews}><BiNews /> News</Nav.Link>
                                <Nav.Link href="#" onClick={handleScroll}><BiEnvelope /> Contact</Nav.Link>
                                <Nav.Link href="#"><BiInfoCircle /> About</Nav.Link>
                            </Nav>
                        </BootstrapNavbar.Collapse>
                    </Container>
                </BootstrapNavbar>
            )}
        </>
    );
};

export default Navbar;
