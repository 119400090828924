import React from 'react';
import "../Staticfiles/ClientNames.css";

const ClientNames = () => {
    return (
        <div className="client-names">
            <div className="client-slider">
                <div className="client">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg" alt="Google" />
                    <p>Google</p>
                </div>
                <div className="client">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/b/b9/2023_Facebook_icon.svg" alt="Facebook" />
                    <p>Facebook</p>
                </div>
                <div className="client">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/5/54/2024_Spotify_logo_without_text.svg" alt="Spotify" />
                    <p>Spotify</p>
                </div>
                <div className="client">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Twitter_bird_logo_black.svg" alt="Twitter" />
                    <p>Twitter</p>
                </div>
                <div className="client">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg" alt="Apple" />
                    <p>Apple</p>
                </div>
                <div className="client">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/LinkedIn_Logo_2013_%282%29.svg" alt="LinkedIn" />
                    <p>LinkedIn</p>
                </div>
                <div className="client">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/f/fd/YouTube_full-color_icon_%282024%29.svg" alt="YouTube" />
                    <p>YouTube</p>
                </div>
                {/* Add more client logos as necessary */}
            </div>
        </div>
    );
}

export default ClientNames;
