import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "../Staticfiles/TestimonialShowcase.css";

const testimonials = [
    {
        name: "Amit Sharma",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget gravida diam. Nulla lectus diam",
        image: "https://randomuser.me/api/portraits/men/32.jpg",
    },
    {
        name: "Priya Singh",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget gravida diam. Nulla lectus diam",
        image: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    {
        name: "Rohan Mehta",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget gravida diam. Nulla lectus diam",
        image: "https://randomuser.me/api/portraits/men/56.jpg",
    },
    {
        name: "Neha Verma",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget gravida diam. Nulla lectus diam",
        image: "https://randomuser.me/api/portraits/women/28.jpg",
    },
];

const TestimonialShowcase = () => {
    const sliderRef = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({ repeat: -1, defaults: { duration: 2, ease: "power2.inOut" } });
        tl.to(sliderRef.current, { x: "-50%", delay: 2 })
            .to(sliderRef.current, { x: "-100%", delay: 2 })
            .to(sliderRef.current, { x: "0%", delay: 2 });
    }, []);

    return (
        <section className="testimonial-section">
            <h2 className="testimonial-heading">
                What Our Testimonial Say
            </h2>
            <div className="testimonial-slider">
                <div className="testimonial-list" ref={sliderRef}>
                    {testimonials.map((item, index) => (
                        <div className="testimonial-box" key={index}>
                            <img src={item.image} alt={item.name} className="testimonial-img" />
                            <div className="testimonial-content">
                                <p className="testimonial-text">"{item.text}"</p>
                                <h4 className="testimonial-name">- {item.name}</h4>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TestimonialShowcase;
