import React, { useState } from "react";
import "../Staticfiles/CodeIntegration.css";
import { FaPython, FaPhp, FaGem, FaCopy, FaJs, FaJava, FaBook } from "react-icons/fa";
import { SiCurl, SiGo } from "react-icons/si";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const tabs = [
    { name: "JavaScript", icon: <FaJs /> },
    { name: "Python", icon: <FaPython /> },
    { name: "Go", icon: <SiGo /> },
    { name: "Java", icon: <FaJava /> },
    { name: "PHP", icon: <FaPhp /> },
    { name: "Ruby", icon: <FaGem /> },
    { name: "cURL", icon: <SiCurl /> },
];

const codeSamples = {
    JavaScript: `const axios = require("axios");
const url = "https://test.authrabbit.com/voter";
const headers = {
    "auth-key": "<auth-key>",
    "Content-Type": "application/json"
};
const data = {
    epicNo: "HR/***/***/*****"
};

axios.post(url, data, { headers })
    .then(response => {
        console.log(response.status);
        console.log(response.data);
    })
    .catch(error => {
        console.error(error.response ? error.response.data : error.message);
});`,
    Python: `import requests
import json

url = "https://test.authrabbit.com/voter"
headers = {
    "auth-key": "<auth-key>",
    "Content-Type": "application/json"
}
data = {
    "epicNo": "HR/***/***/*****"
}

response = requests.post(url, headers=headers, data=json.dumps(data))

print(response.status_code)
print(response.json())`,
    Go: `package main

import (
	"bytes"
	"encoding/json"
	"fmt"
	"net/http"
	"io/ioutil"
)

func main() {
	url := "https://test.authrabbit.com/voter"
	data := map[string]string{"epicNo": "HR/***/***/*****"}
	jsonData, _ := json.Marshal(data)

	req, _ := http.NewRequest("POST", url, bytes.NewBuffer(jsonData))
	req.Header.Set("auth-key", "<auth-key>")
	req.Header.Set("Content-Type", "application/json")

	client := &http.Client{}
	resp, err := client.Do(req)
	if err != nil {
		fmt.Println(err)
		return
	}
	defer resp.Body.Close()

	body, _ := ioutil.ReadAll(resp.Body)
	fmt.Println(resp.StatusCode)
	fmt.Println(string(body))
}`,
    Java: `import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import java.net.URI;

public class VoterVerification {
    public static void main(String[] args) throws Exception {
        String url = "https://test.authrabbit.com/voter";
        String json = "{\"epicNo\":\"HR/***/***/*****\"}";

        HttpClient client = HttpClient.newHttpClient();
        HttpRequest request = HttpRequest.newBuilder()
                .uri(URI.create(url))
                .header("auth-key", "<auth-key>")
                .header("Content-Type", "application/json")
                .POST(HttpRequest.BodyPublishers.ofString(json))
                .build();

        HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());

        System.out.println(response.statusCode());
        System.out.println(response.body());
    }
}`,
    PHP: `<?php
$url = "https://test.authrabbit.com/voter";
$data = json_encode(["epicNo" => "HR/***/***/*****"]);

$ch = curl_init($url);
curl_setopt($ch, CURLOPT_HTTPHEADER, [
    "auth-key: <auth-key>",
    "Content-Type: application/json"
]);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_POST, true);
curl_setopt($ch, CURLOPT_POSTFIELDS, $data);

$response = curl_exec($ch);
$http_status = curl_getinfo($ch, CURLINFO_HTTP_CODE);
curl_close($ch);

echo "Status Code: " . $http_status . "\n";
echo "Response: " . $response . "\n";
?>`,
    Ruby: `require 'net/http'
require 'uri'
require 'json'

url = URI("https://test.authrabbit.com/voter")
http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true

request = Net::HTTP::Post.new(url)
request["auth-key"] = "<auth-key>"
request["Content-Type"] = "application/json"
request.body = { epicNo: "HR/***/***/*****" }.to_json

response = http.request(request)
puts response.code
puts response.body`,
    cURL: `curl --location 'https://test.authrabbit.com/voter' \
--header 'auth-key: <auth-key>' \
--header 'Content-Type: application/json' \
--data '{
    "epicNo": "HR/***/***/*****"
}'`,
};

const CodeIntegration = () => {
    const [activeTab, setActiveTab] = useState("JavaScript");
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(codeSamples[activeTab]);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);  // 2 seconds ke liye dikhayega
    };

    return (
        <>
            <div className="windowCodeIntegration">
                <h2 className="IntegrationHeading text-center fw-bold">Integrations</h2>
                <div className="code-integration-sleek mt-4">
                    <div className="code-tab-sleek-header">
                        {tabs.map((tab, index) => (
                            <span
                                key={index}
                                className={`code-tab-sleek ${activeTab === tab.name ? "code-tab-active" : ""}`}
                                onClick={() => setActiveTab(tab.name)}
                            >
                                <span className="code-tab-icon">{tab.icon}</span> {tab.name}
                            </span>
                        ))}
                    </div>
                    <div className="code-content-sleek">
                        <SyntaxHighlighter
                            language={activeTab.toLowerCase()}
                            style={atomDark}
                            showLineNumbers
                        >
                            {codeSamples[activeTab]}
                        </SyntaxHighlighter>

                    </div>
                    <div className="copy-container mt-3">

                        <button className="copy-button" onClick={() => window.open('https://apidocs.authrabbit.com/api', '_blank')}>
                        <i class="bi bi-box-arrow-up-right"></i> &nbsp;&nbsp;Go to Docs
                        </button>
                        <button className="copy-button float-end" onClick={copyToClipboard}>
                            <FaCopy /> Copy
                            {copied && <span className="copied-tag">Copied!</span>}
                        </button>



                    </div>
                </div>
            </div>
        </>
    );
};

export default CodeIntegration;
