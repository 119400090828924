import React, { useState } from "react";
import "../Staticfiles/ContactUs.css";
import ContactImage from "../Images/contactusfinal.png";
import emailjs from "@emailjs/browser";
import { ThreeDot } from 'react-loading-indicators';


const ContactForm = () => {
    const [formType, setFormType] = useState("business");
    const [formData, setFormData] = useState({
        fullName: "",
        businessEmail: "",
        company: "",
        contactNumber: "",
        lookingFor: "",
        privacyNotice: false,
    });

    const [formErrors, setFormErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);



    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.fullName.trim()) errors.fullName = "Full Name is required";
        if (!formData.businessEmail.trim()) errors.businessEmail = "Business Email is required";
        if (formType === "business" && !formData.company.trim()) errors.company = "Company is required";
        if (!formData.contactNumber.trim()) errors.contactNumber = "Contact Number is required";
        if (!formData.lookingFor) errors.lookingFor = "Please select an option";
        if (!formData.privacyNotice) errors.privacyNotice = "You must agree to the Privacy Notice";

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSuccessMessage("");
        setErrorMessage("");
        setIsSubmitted(true);

        setLoading(true);



        if (validateForm()) {
            emailjs
                .send(
                    "service_4swufja",
                    "template_kixc99y",
                    formData,
                    "uuc4esgP3ymmbMlkH"
                )
                .then(
                    (result) => {
                        console.log("SUCCESS!", result.text);
                        setSuccessMessage("✅ Form submitted successfully. We'll get back to you soon!");
                        setFormData({
                            fullName: "",
                            businessEmail: "",
                            company: "",
                            contactNumber: "",
                            lookingFor: "",
                            privacyNotice: false,
                        });
                        setIsSubmitted(false);
                        setLoading(false);
                    },
                    (error) => {
                        console.error("FAILED...", error.text);
                        setErrorMessage("❌ Something went wrong. Please try again later.");
                    }
                );
        }
    };

    return (
        <div className="contact-form-section" id="targetSection">
            <div className="contact-form-container">
                {/* Left Side - Image */}
                <div className="contact-form-image">
                    <img src={ContactImage} alt="Contact" />
                </div>

                {/* Right Side - Form */}
                <div className="contact-form-content">
                    <h1 className="contact-form-title">Ready to Explore AuthRabbit for your business?</h1>
                    <p className="contact-form-description">
                        Fill out the form and we’ll be in touch within 24 hours.
                    </p>



                    <form className="contact-form" onSubmit={handleSubmit}>
                        <label>
                            Full Name <span className="asterisk">*</span>
                            <input
                                type="text"
                                placeholder="Full Name"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                className={formErrors.fullName ? "error-input" : ""}
                            />
                            {formErrors.fullName && <p className="error-message">{formErrors.fullName}</p>}
                        </label>

                        <div className="contact-form-inline">
                            <label>
                                Business Email <span className="asterisk">*</span>
                                <input
                                    type="email"
                                    placeholder="Business Email"
                                    name="businessEmail"
                                    value={formData.businessEmail}
                                    onChange={handleChange}
                                    className={formErrors.businessEmail ? "error-input" : ""}
                                />
                                {formErrors.businessEmail && <p className="error-message">{formErrors.businessEmail}</p>}
                            </label>
                            {formType === "business" && (
                                <label>
                                    Company <span className="asterisk">*</span>
                                    <input
                                        type="text"
                                        placeholder="Company"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleChange}
                                        className={formErrors.company ? "error-input" : ""}
                                    />
                                    {formErrors.company && <p className="error-message">{formErrors.company}</p>}
                                </label>
                            )}
                        </div>

                        <label>
                            Contact Number <span className="asterisk">*</span>
                            <input
                                type="text"
                                placeholder="Contact Number"
                                name="contactNumber"
                                value={formData.contactNumber}
                                onChange={handleChange}
                                className={formErrors.contactNumber ? "error-input" : ""}
                            />
                            {formErrors.contactNumber && <p className="error-message">{formErrors.contactNumber}</p>}
                        </label>

                        <label>
                            What are you looking for? <span className="asterisk">*</span>
                            <select
                                name="lookingFor"
                                value={formData.lookingFor}
                                onChange={handleChange}
                                className={formErrors.lookingFor ? "error-input" : ""}
                            >
                                <option value="">Select an option</option>
                                <option value="needs">Assess your team’s needs</option>
                                <option value="features">Learn more about AuthRabbit Product & features</option>
                                <option value="pricing">Discuss pricing options</option>
                            </select>
                            {formErrors.lookingFor && <p className="error-message">{formErrors.lookingFor}</p>}
                        </label>

                        {/* ✅ Checkbox validation ke saath styling */}
                        <div className="contact-form-checkboxes-vertical">
                            <label style={{ color: isSubmitted && !formData.privacyNotice ? "red" : "" }}>
                                <input
                                    type="checkbox"
                                    name="privacyNotice"
                                    checked={formData.privacyNotice}
                                    onChange={handleChange}
                                    style={{
                                        outline: isSubmitted && !formData.privacyNotice ? "2px solid red" : "none",
                                    }}
                                />
                                I have read and agree to AuthRabbit's Privacy Notice. <span className="asterisk">*</span>
                                {formErrors.privacyNotice && <p className="error-message">{formErrors.privacyNotice}</p>}
                            </label>
                            <label>
                                <input type="checkbox" /> I agree to receive newsletters.
                            </label>
                        </div>

                        <p className="contact-form-footer">
                            By submitting this form, you are agreeing to our <a href="/privacy-policy">privacy policy</a>.
                        </p>


                        {successMessage && <p className="success-message">{successMessage}</p>}
                        {errorMessage && <p className="error-message">{errorMessage}</p>}


                        {!loading ? (
                            <button
                                type="submit"
                                className="contact-form-submit-btn w-50"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <ThreeDot variant="pulsate" color="#0a9d96" size="medium" text="" textColor="" />
                            </div>

                        )}




                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
