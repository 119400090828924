import React from "react";
import './App.css'
import Navbar from "./Components/Navbar";
import HeroSection from "./Components/HeroSection";
import EnterpriseSolutions from "./Components/EnterpriseSolutions";
import Footer from "./Components/Footer";
import EnterpriseProducts from "./Components/EnterpriseProducts";
import ProgressDesign from "./Components/ProgressDesign";
import TestimonialShowcase from "./Components/TestimonialShowcase";
import ContactUs from "./Components/ContactUs";
import ClientNames from "./Components/ClientNames";
import BlogCards from "./Components/BlogCards";
import FAQ from "./Components/FAQ";
import CodeIntegration from "./Components/CodeIntegration";
import BusinessSafety from "./Components/BusinessSafety";


const App = () => {
    return (
        <>
            <Navbar />
            <HeroSection />
            <ClientNames />
            <EnterpriseSolutions />
            <EnterpriseProducts />
            <ProgressDesign />
            <CodeIntegration />
            <BusinessSafety />
            <BlogCards />
            <FAQ />
            <TestimonialShowcase />
            <ContactUs />
            <Footer />
        </>
    );
};

export default App;
